import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import CommentIcon from '@mui/icons-material/Comment';
import SearchIcon from '@mui/icons-material/Search';
import BasicModal from './BasicModal';
import ImageViewer from 'react-simple-image-viewer';
import ImageModal from './ImageModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { green, red } from '@mui/material/colors';
import { toast } from 'react-toastify';
import Loader from './Loader';
import moment from 'moment/moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Logout from './Logout';
import BasicApproveData from './BasicApproveData';
import IntialAmountPopup from './IntialAmountPopup';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//TABLE HEADINGS
const headCells = [
  { id: 'id',
    label: 'Contact Phone Number'
  },
  {
    id: 'name',
    label: 'Name',
    fontWeight: 'bold',
  },
  {
    id: 'email',

    label: 'Email',
  },
  {
    id: 'fat',

    label: 'Communities Email',
  },
  {
    id: 'date',

    label: 'Submitted Date',
  },
  {
    id: 'carbs',

    label: 'Files',
  },
  {
    id: 'comment',
    label: 'Consumer Comment',
  },
  {
    id: 'admincomment',
    label: 'Admin Comment',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'paymentStatus',
    label: 'Payment Status',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'Action',
    label: 'Action',
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} style={{ fontWeight: 'bold' }} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function BasicData() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [setId, getId] = useState('');
  const [approveId, setApproveId] = useState('');
  const [InitialAmountId, setIntialAmountId] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openApprove, setOpenApprove] = React.useState(false);
  const [openAmountPopup, setInitialAmount] = React.useState(false);
  const [openImage, setopenImage] = useState('');
  const [imageZoom, setImageZoom] = useState('');
  const handleOpen = () => setOpen(true);
  const handleOpenApprove = () => setOpenApprove(true);
  const handleopenInitialAmount = () => setInitialAmount(true);
  const handleClose = () => setOpen(false);
  const handleCloseApprove = () => setOpenApprove(false);
  const handleCloseInitialAmount = () => setInitialAmount(false);
  const handleOpenImage = () => setopenImage(true);
  const handleCloseImage = () => setopenImage(false);
  const [data, setData] = useState([]);
  const [filterData, setFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleClickOpen = () => {
    setOpenDel(true);
  };

  const handleCloseDel = () => {
    setOpenDel(false);
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };


  const token = localStorage.getItem('accessToken');
  const openImageViewer = useCallback((index) => {
    window.open(`${index}`, '_blank');
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}admin/get_all_user_project_information_list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        }
      );
      if (response?.data?.status) {        
        setData(response?.data?.data);
        setFiltered(response?.data?.data); 
        setIsLoading(false);
      } else {
        console.log("No Data Fetch_____")
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(true);
    }
  };

  useEffect(() => {token && getData()}, [token]);

  const approved = async (id, value) => {
    setIsLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}admin/add_admin_approve_unapprove/${id}`,
        {
          isApproved: value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
      })
      .catch((res) => {
        setIsLoading(false);
        toast.error(err.data.message);
      });

    getData();
  };
  const deleteData = async (id) => {
    setIsLoading(true);
    console.log(id, 'id');
    await axios
      .delete(`${process.env.REACT_APP_API}admin/delete_projects/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-api-key': process.env.REACT_APP_API_KEY,
          platform: process.env.REACT_APP_platform,
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        console.log("Error Logooooo", err);
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });

    getData();
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;



    return (
      <>
        {isLoading && <Loader />}
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color='inherit'
              variant='subtitle1'
              component='div'
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant='h6'
              id='tableTitle'
              component='div'
            >
              
            </Typography>
          )}
        </Toolbar>
      </>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  
  const commentAdd = (id) => {
    setOpen(true);
    getId(id);
  };

  const  approveddata = async (id) => {
    // setOpenApprove(true);
    // setApproveId(id);

    setIsLoading(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}admin/add_admin_approve_unapprove/${id}`,
        {
          isApproved: "1",
          amount: "1"
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        }
      ).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
      })
      .catch((res) => {
        setIsLoading(false);
        toast.error(err.data.message);
      });

      getData();

    } catch (error) {
      console.log(error);
    }

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    window.scrollTo(0, 0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const AddIntialPayments = (id) => {
    setInitialAmount(true);
    setIntialAmountId(id);
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChange = (e) => {
    // console.log(e.target.value);
    // const mydata = [...data]
    if (e?.target?.value != '') {
      const tableData = filterData?.filter((item) => {
        return (
          item?.contactPhoneNumber?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item?.email?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      // console.log(tableData);
      setData(tableData);
      setPage(0);
    } else {
      setData(filterData);
      // console.log(data);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  function NewTab() {}
  return (
    <>
    {isLoading && <Loader />}



    <div className='hdingDta'>
      <h1> Project Information </h1>

    </div>  


      <Box
        sx={{
          width: '90%',
          margin: 'auto',
          marginTop: '1.5rem',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            marginBottom: '20px',
          }}
        >
          <div
            className='search-box'
            style={{
              display: 'flex',
              boxShadow: 'rgba(0, 0, 0, 0.35) 1.95px 0.95px 2.6px',
              padding: '10px',
              borderRadius: '20px',
            }}
          >
            <SearchIcon />
            <input
              type='search'
              placeholder='Search...'
              onChange={handleChange}
              style={
                selected.length
                  ? {
                      border: 'none',
                      outline: 'none',
                      background: '#e3eefa',
                      width: '100%',
                    }
                  : { border: 'none', outline: 'none', width: '100%' }
              }
            />
          </div>
          <Logout />  
        </div>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby='tableTitle'
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />

              {data.length ? (
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          style={{
                            border: '1px solid rgba(224, 224, 224, 1)',
                          }}
                        >
                          <TableCell >
                            {row?.contactPhoneNumber}
                          </TableCell>
                          <TableCell >
                            {row.name}
                          </TableCell>

                          <TableCell >
                            {row.email}
                          </TableCell>

                          <TableCell >
                            {row.communitiesEmailAddress}
                          </TableCell>

                          <TableCell >
                            {moment(row.createdAt).format('L')}
                          </TableCell>

                          <TableCell >
                          
                          {row?.externalFiles && (                            
                          <img  className='WdthPge' onClick={() =>
                                      openImageViewer(row?.externalFiles)
                                    } src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png' alt='icon' />

                            )}
     

                            <AvatarGroup
                              max={5}
                              style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'flex-end',
                              }}
                            >
                              
                              {row?.uploadedFiles?.map((item, imgIndex) => {
                                  return (
                                    <img key={imgIndex} className='WdthPge' onClick={() =>
                                      openImageViewer(item?.fileName)
                                    } src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png' alt='icon' />
                                  );
                              })}

                            </AvatarGroup>
                          </TableCell>

                          <TableCell >
                            <Tooltip title={row?.comments}>
                              <p>
                                {row?.comments?.length > 10
                                  ? `${row.comments.slice(0, 10)}....`
                                  : row.comments}
                              </p>
                            </Tooltip>
                          </TableCell>

                          <TableCell >
                            <Tooltip title={row?.adminComments}>
                              <p>
                                {row?.adminComments?.length > 4
                                  ? `${row.adminComments.slice(0, 4)}....`
                                  : row.adminComments}
                              </p>
                            </Tooltip>
                          </TableCell>

                          <TableCell >
                            ${row?.amount ?? 0}
                          </TableCell>

                          <TableCell >
                            {row?.paymentStatus}
                          </TableCell>

                          <TableCell >
                            {row?.isApproved == '1'
                              ? 'Approved'
                              : row.isApproved == '0'
                              ? 'Not Approved'
                              : 'none'}
                          </TableCell>
                          <TableCell
                            style={{ display: 'flex', border: 'none' }}
                          >
                            <Tooltip title='Comment'>
                              <IconButton>
                                <CommentIcon
                                  onClick={() => commentAdd(row?.id)}
                                />
                              </IconButton>
                            </Tooltip>

{/*                                                        
                            {row?.isApproved == '1'? <> 
                             <Tooltip>
                              <IconButton>
                                <AddCircleOutlineIcon
                                  sx={{ color: green[500] }}
                                />
                              </IconButton>
                            </Tooltip> </> :
                            <>                               */}
                                {/* <Tooltip title='Add Current Payment'>
                                  <IconButton>
                                    <AddCircleOutlineIcon onClick={() => AddIntialPayments(row?.id)} />
                                  </IconButton>
                                </Tooltip> */}
                            {/* </> } */}
                           
                            { row?.isApproved == '1'?  <> 
                             <Tooltip title='Already Approved'>
                              <IconButton>
                                <CheckCircleIcon
                                  sx={{ color: green[500] }}
                                />
                              </IconButton>
                            </Tooltip> </> :
                            <>                              
                                <Tooltip title='Approve'>
                                  <IconButton>
                                    <CheckCircleIcon
                                      sx={{ color: green[500] }}
                                      onClick={() => approveddata(row?.id)}
                                    />
                                  </IconButton>
                                </Tooltip>
                            </> }


                            <Tooltip title='Not Approved'>
                              <IconButton>
                                <CancelIcon
                                  sx={{ color: red[500] }}
                                  onClick={() => approved(row.id, '0')}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete'>
                              <IconButton>
                                <DeleteForeverIcon
                                  sx={{ color: red[500] }}
                                  // onClick={() => deleteData (row.id)}
                                  onClick={() => {
                                    handleClickOpen();
                                    setDeleteId(row.id);
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <>
                 
                <TableRow style={{ position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      left: '50%',
                      marginTop: '150px',
                      fontSize: '20px',
                    }}
                  >
                    {/* No Data */}
                  </div>
                </TableRow>
                </>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label='Dense padding'
        />
      </Box>

      <BasicModal 
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        setId={setId}
        getData={getData}
      />

      <IntialAmountPopup
        openAmountPopup={openAmountPopup}
        setInitialAmount={setInitialAmount}
        handleopenInitialAmount={handleopenInitialAmount}
        handleCloseInitialAmount={handleCloseInitialAmount}
        InitialAmountId={InitialAmountId}
        getData={getData}
      />

      {/* <BasicApproveData
        openApprove={openApprove}
        setOpenApprove={setOpenApprove}
        handleOpenApprove={handleOpenApprove}
        handleCloseApprove={handleCloseApprove}
        approveId={approveId}
        getData={getData}
      /> */}
      <ImageModal
        openImage={openImage}
        setopenImage={setopenImage}
        handleOpenImage={handleOpenImage}
        handleCloseImage={handleCloseImage}
        imageZoom={imageZoom}
      />
      <Dialog
        open={openDel}
        onClose={handleCloseDel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'></DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Do you want to delete this record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDel}>Cancel</Button>
          <Button
            onClick={() => {
              handleCloseDel();
              deleteData(deleteId);
            }}
            autoFocus
            className='text-danger'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
