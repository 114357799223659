import './App.css';
import Login from './Login';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import BasicData from './components/BasicData';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Logout from './components/Logout';

function App() {
  const existingUser = localStorage.getItem('accessToken');

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route
            exact
            path='/Dashboard'
            element={
                <BasicData />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
