import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import Loader from './components/Loader';

const Login = () => {
  const [credetails, setCredetails] = useState({
    email: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [toastId, setToastId] = useState(null);
  const submitForm = () => {

    let errorMessage = '';
    if (credetails.email === '') {
      errorMessage = 'Please Enter Your Email Address And Password';
    } else if (!validateEmail(credetails.email)) {
      errorMessage = 'Please Enter A Valid Email Address';
    } else if (credetails.password === '') {
      errorMessage = 'Please Enter Password';
    }
    // if (credetails.email === '' || credetails.password === '') {
    //    toast.error('Please Enter Username & Password');
    //    return 
    // }else { 

    
    
    if (errorMessage !== '' && !toast.isActive(toastId)) {
        const newToastId = toast.error(errorMessage);
        setToastId(newToastId);
      } else if (errorMessage === '') {  
      setIsLoading(true);
      axios
        .post(`${process.env.REACT_APP_API}auth/admin/login`, credetails, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        })
        .then((res) => {
          setIsLoading(false);
          toast.success(res.message);
          navigate('/Dashboard');
          localStorage.setItem('accessToken', res.data.data.token.accessToken);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }
  const handleChange = (e) => {
    setCredetails({
      ...credetails,
      [e.target.name]: e.target.value,
    });
  };







  return (
    <>
      {isLoading && <Loader />}
      <main className='bg-light text-dark background-main'>
        <div className='container'>
          <div className='row justify-content-center align-items-center vh-100 py-5 '>
            <div className='col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5'>
              <div
                className='text-center text-white mb-4 fs-1'
                style={{ color: '#000', fontWeight: 'inherit' }}
              ></div>

              <div
                className='card card-body rounded-3 p-4 p-sm-5 mb-5 '
                style={{ opacity: '80%' }}
              >
                <div className='text-center'>
                  <h1 className='mb-2'> Login</h1>
                </div>
                <div className='mt-4'>
                  <div className='mb-3 input-group-lg'>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Email Address'
                      name='email'
                      value={credetails.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='mb-3 input-group-lg'>
                    <input
                      type='password'
                      className='form-control'
                      placeholder='Password'
                      name='password'
                      value={credetails.password}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className='mb-3 d-sm-flex justify-content-between'>
                    <div className='form-check'>
                      <input
                        type='checkbox'
                        checked={credetails.persist}
                        name='persist'
                        className='form-check-input pr-2'
                        id='persist'
                        value={credetails.persist}
                      />
                      <label
                        className='form-check-label ml-2 '
                        htmlFor='persist'
                      >
                        {' '}
                        Remember me
                      </label>
                    </div>
                  </div> */}
                  <div className='d-grid'>
                    <button
                      onClick={submitForm}
                      className='btn btn-outline-primary'
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
